export const getCacheBustedURL = (url: string): string => {
  const urlObj = new URL(url);
  urlObj.searchParams.set('ts', new Date().getTime().toString());

  return urlObj.href;
};

export function getURISegment(segmentIndex: number = 0): string | null {
  const path = window.PMW.util.getCurrentPagePath().replace(/^\/(.*)/, '$1');
  const segments = path.split('/');
  const isValidIndex = segmentIndex < segments.length;

  return isValidIndex ? segments[segmentIndex] : null;
}

export const isValidURL = (urlToValidate: string): boolean => {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/{2}=]*)/;
  return regex.test(urlToValidate);
};

export const hasInvalidCharacters = (urlToValidate: string): boolean => {
  const pattern = /["<>#%{}|^~[\]`]/;
  return pattern.test(urlToValidate);
};

export const getFilenameFromURL = (url: string): string => {
  const splitted = url.split('/');
  return splitted.pop()!;
};

export const isValidClickableLink = (url: string): boolean => {
  return url === '' || window.PMW.util.isValidURLSimple(url) || window.PMW.util.isValidPhoneSimple(url);
};

export const prependProtocol = (url: string): string => {
  let protocol = 'https://';
  let protocolRegex = /^https?:\/\//i;

  // if (/@/i.test(url)) {
  //   protocol = 'mailto:';
  //   protocolRegex = /mailto:/i;
  // } else
  if (window.PMW.util.isValidPhoneSimple(url)) {
    protocol = 'tel:';
    protocolRegex = /tel:/i;
  }

  return protocolRegex.test(url) ? url : protocol + url;
};

export const getStateFromUrl = (url: string): string => {
  return url.split('state=')[1].split('&')[0];
};


export const isGoogleMeetLink = (url: string): boolean => {
  let parsedUrl = null;

  try {
    parsedUrl = new URL(url);
  } catch (e) {
    return false;
  }
  const host = parsedUrl.hostname;
  return host === 'meet.google.com' || /\.meet\.google\.com$/.test(host);
};

export const isZoomLink = (url: string): boolean => {
  let parsedUrl = null;

  try {
    parsedUrl = new URL(url);
  } catch (e) {
    return false;
  }
  const host = parsedUrl.hostname;
  return /\.?zoom\.(us|com)$/.test(host);
};

export const isWebpURL = (url: string): boolean => {
  return url.includes('.webp');
}

export const convertWebpURLToJPG = (url: string): string => {
  if (isWebpURL(url)) {
    return url.replace(/\.webp$/, '.jpg');
  }

  return url;
}